<template>
    <div>
        <div v-if="loadingDetailOrder" class="ol-dashboard__order"> 
            <SkeletonLoader height="50px" width="100%" /><br>
            <SkeletonLoader height="100px" width="100%" /><br>
            <SkeletonLoader height="100px" width="100%" />
            <SkeletonLoader height="100px" width="100%" />
        </div>
        <div v-else class="ol-dashboard__order"> 
            <h4 class="ol-dashboard__container-title">Detail Pesanan - {{ data.ordersn }}</h4>
            <h4 class="ol-dashboard__order-label">Status Pesanan</h4>
            <div v-if="data.status == 92" class="ol-dashboard__order-status ol-dashboard__order-status--detail ol-dashboard__order-status--detail-cancel">
                <p class="ol-dashboard__order-status-info">
                    Pesanan ini telah dibatalkan secara otomatis pada <strong>{{ data.faspay_expired_date_indonesia }} WIB</strong> karena telah melewati batas waktu pembayaran.
                    <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                </p>
                <div class="ol-dashboard__order-status-action">
                    <a @click="toCart(data.r_more_transaction_order_details[0].r_more_transaction_order_products)"  class="ol-btn">Beli Lagi</a>
                </div>
            </div>
            <div v-else-if="data.status == 91" class="ol-dashboard__order-status ol-dashboard__order-status--detail ol-dashboard__order-status--detail-cancel">
                <p class="ol-dashboard__order-status-info">
                    Pesanan ini telah dibatalkan pada <strong>{{ data.canceled_at_indonesia }} WIB</strong> dengan alasan {{ data.note_cancel }}.
                    <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                </p>
                <div class="ol-dashboard__order-status-action">
                    <a @click="toCart(data.r_more_transaction_order_details[0].r_more_transaction_order_products)"  class="ol-btn">Beli Lagi</a>
                </div>
            </div>

            <div v-else-if="data.status == 5" class="ol-dashboard__order-status ol-dashboard__order-status--detail ol-dashboard__order-status--detail-cancel">
                <p class="ol-dashboard__order-status-info">
                    Pesanan ini telah diajukan komplain pada <strong>{{ data.complained_at_indonesia }} WIB</strong> dengan alasan {{ data.r_more_complain.reason }}.
                    <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                </p>
                <div class="ol-dashboard__order-status-action">
                    <router-link to="/profile/complain-detail"  class="ol-btn">Informasi Komplain</router-link>
                </div>
            </div>
            <div v-else class="ol-checkout-step ol-dashboard__order-step">

                <div class="ol-checkout-step__item ol-checkout-step__item--progress">
                    <div><i class="ol-fa fas fa-clipboard-list"></i></div>
                    <span>Dibuat</span>
                    <small>{{ data.created_at_indonesia }} WIB</small>
                </div>
                <div v-if="data.status >= 3 && data.status <= 6" class="ol-checkout-step__item ol-checkout-step__item--progress">
                    <div><i class="ol-fa fas fa-box"></i></div>
                    <span>Diproses</span>
                    <small>{{ data.processed_at_indonesia }} WIB</small>
                </div>
                <div v-else class="ol-checkout-step__item">
                    <div><i class="ol-fa fas fa-box"></i></div>
                    <span>Diproses</span>
                </div>
                <div v-if="data.status >= 4 && data.status <= 6" class="ol-checkout-step__item ol-checkout-step__item--progress">
                    <div><i class="ol-fa fas fa-truck"></i></div>
                    <span>Dikirim</span>
                    <small>{{ data.shipped_at_indonesia }} WIB</small>
                </div>
                <div v-else class="ol-checkout-step__item">
                    <div><i class="ol-fa fas fa-truck"></i></div>
                    <span>Dikirim</span>
                </div>

                <div v-if="data.status == 6" class="ol-checkout-step__item ol-checkout-step__item--progress">
                    <div><i class="ol-fa fas fa-check"></i></div>
                    <span>Selesai</span>
                    <small>{{ data.completed_at_indonesia }} WIB</small>
                </div>
                <div v-else class="ol-checkout-step__item">
                    <div><i class="ol-fa fas fa-check"></i></div>
                    <span>Selesai</span>
                </div>
            </div>

            <div v-if="data.status != 91 && data.status != 92 && data.status != 5" class="ol-dashboard__order-status ol-dashboard__order-status--detail">
                <div v-if="data.status == 1">
                    <p class="ol-dashboard__order-status-info">
                        Segera lakukan pembayaran paling lambat <strong>{{ data.faspay_expired_date_indonesia }} WIB</strong>. Jika melebihi waktu tersebut maka pesanan akan otomatis dibatalkan.
                        <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                    </p>
                    <div class="ol-dashboard__order-status-action">
                        <router-link to="/profile/pay" class="ol-btn">Bayar Sekarang</router-link>
                        <a @click="showPopup('popup-cancel')" class="ol-btn ol-btn--third">Batalkan Pesanan</a>
                    </div>
                </div>
                <div v-if="data.status == 2">
                    <p>
                        Pembayaran pesanan telah berhasil. Pesanan akan masuk proses pengiriman paling lambat <strong>{{ data.processed_latest_at_indonesia }} WIB</strong>.
                        <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                    </p>
                </div>
                <div v-if="data.status == 3">
                    <p>
                        Pesanan sedang dalam proses pengemasan. Pesanan akan dikirim paling lambat <strong>{{data.shipped_latest_at_indonesia}} WIB</strong>.
                        <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                    </p>
                </div>
                <div v-if="data.status == 4" >
                    <p class="ol-dashboard__order-status-info">
                        <span v-if="infoResi && infoResi != null" v-html="infoResi"></span> 
                        <br>Klik <strong>Terima Pesanan</strong> jika pesanan sudah diterima. <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                    </p>
                    <div class="ol-dashboard__order-status-action">
                        <a v-if="jsonResi && jsonResi.length > 0" @click="showPopup('popup-tracking')" class="ol-btn ol-btn--third">Lacak Pengiriman</a>
                        <button @click="showConfirmPopup('popup-complete')" class="ol-btn" style="margin-left:10px">Terima Pesanan</button>
                    </div>
                </div>
                
                <div v-if="data.status == 6">
                    <p class="ol-dashboard__order-status-info">
                        Pesanan telah selesai pada <strong>{{ data.completed_at_indonesia }} WIB</strong>. Terima kasih sudah berbelanja di MORE.
                        <br><br><a :href="wa" target="_blank">Hubungi Kami</a> untuk informasi lebih lanjut.
                        <br>
                        <router-link to="/profile/review" v-if="data.reviewed == 0" style="margin-top: 10px; float: left;text-decoration:none;" class="ol-btn"><span style="color:#FFFFFF;">Tulis Ulasan</span></router-link>    
                    </p>

                    <div class="ol-dashboard__order-status-action">
                        <a @click="toCart(data.r_more_transaction_order_details[0].r_more_transaction_order_products)"  class="ol-btn">Beli Lagi</a>
                    </div>
                </div>
                <div v-if="data.status > 1">
                    <router-link to="/invoice" target="_blank" style=" margin-top: 10px; float: right; " class="ol-btn ol-btn--third">Cetak Tagihan</router-link>
                </div>
            </div>

            <div class="ol-dashboard__order-address">
                <div class="ol-dashboard__order-address-item">
                    <h3 class="ol-dashboard__order-address-item-title">Alamat Pengiriman</h3>

                    <p class="ol-dashboard__order-address-item-desc">
                        <b>{{ data.receiver_name }}</b><br>
                        {{ data.receiver_phone }}<br>
                        {{ data.address}}, {{ data.villages }},{{data.districts}}, {{data.cities}}, {{ data.provinces }}, {{ data.postal_code}}
                    </p>
                </div>
                <div class="ol-dashboard__order-address-item">
                    <h3 class="ol-dashboard__order-address-item-title">Metode Pembayaran</h3>

                    <img :srcset="data.r_uni_gen_payment_faspay.icon_url" style="height:25px!important" >
                    <h4 class="ol-dashboard__order-address-item-name">
                        {{ data.r_uni_gen_payment_faspay.type + " (" + data.r_uni_gen_payment_faspay.name + ") " + tenor_txt }}
                    </h4>
                </div>
            </div>
            <div class="ol-cart-list ol-dashboard__order-list">
                <div class="ol-cart-list__body">

                    <div class="ol-cart-list__body--seller">
                        <div  v-for="items in data.r_more_transaction_order_details" :key="'ordet-'+items.id">
                            <div v-for="item in items.r_more_transaction_order_products" :key="'detail-'+item.id" class="ol-dashboard__order-list-item ol-dashboard__order-list-item--body">
                                <div class="ol-dashboard__order-list-item-prd">
                                    <figure class="ol-cart-list__body-item-figure">
                                        <router-link :to="'/product/'+item.r_uni_product_variant.r_uni_product.slug" >
                                            <img v-if="item.r_uni_product_variant.r_uni_product_image" :srcset="item.r_uni_product_variant.r_uni_product_image.image_url" alt="" title="">
                                            <img v-else :srcset="noImage()" alt="" title="">
                                        </router-link>

                                    </figure>
                                    <div class="ol-cart-list__body-item-desc">
                                        <h3 class="ol-cart-list__body-item-desc-name">
                                            <router-link :to="'/product/'+item.r_uni_product_variant.r_uni_product.slug" >{{ item.r_uni_product_variant.name_long}}</router-link>
                                        </h3>
                                        <span class="ol-cart-list__body-item-desc-type">
                                            <small v-if="item.r_uni_product_variant.variant">Varian: <span>{{ item.r_uni_product_variant.variant }}</span></small>
                                            <small v-else>Warna: <span>{{  item.r_uni_product_variant.color }}</span></small>
                                            <b v-if="item.is_free == 1"><br>{{item.free_description}}</b>
                                        </span>
                                    </div>
                                </div>

                                <h4 class="ol-dashboard__order-list-item-price">
                                    <span v-if="item.price_normal" class="ol-home-product__content-item-list--item-price-real">{{ item.price_normal_rp }}</span>
                                    {{ item.sub_total_rp }}
                                </h4>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="ol-cart-list__footer">
                    <div class="ol-cart-list__footer-item">
                        <p><span v-if="data.note">Catatan : <strong>{{ data.note }}</strong></span></p> 
                    </div>
                    <div class="ol-cart-list__footer-item">
                        <dl class="ol-cart-list__footer-item-total">
                            <dt>Sub Total</dt>
                            <dd>{{ sub_total  }}</dd>
                            <!-- // TODO: uncomment tampilan biaya layanan -->
                            <!-- <dt v-if="fee">Biaya Layanan</dt>
                            <dd v-if="fee">{{ fee  }}</dd> -->
                            <dt>Biaya Pengiriman</dt>
                            <dd> {{ total_onngkir  }}</dd>
                            <dt v-if="data.voucher_code">Voucher <strong>#{{ data.voucher_code }}</strong></dt>
                            <dd v-if="data.voucher_code"><strong>-{{ voucher}}</strong> </dd>
                            <dt><strong>Total</strong></dt>
                            <dd><strong>{{ data.total_rp }}</strong></dd>
                        </dl>
                    </div>
                </div>

            </div>
        </div>

        <ConfirmPopup title="Selesaikan Pesanan ? " id="popup-complete" description="Dengan klik Pesanan Sesuai, dana akan diteruskan kepenjual. Pastikan kamu sudah menerima barang yang sesuai dengan pesananmu.">
            <router-link to="/profile/complain" class="ol-btn ol-btn--third">Ajukan Komplain</router-link>
            <a v-if="!loadingComplete" @click="onComplete()" class="ol-btn">Pesanan Sesuai</a>
            <a v-else class="ol-btn" style="pointer-events: none;"> &emsp;<i class="fas fa-spinner fa-pulse ol-fa"></i> Pesanan Sesuai</a>
        </ConfirmPopup>

        <Popup title="Lacak Pengiriman" id="popup-tracking">
            <div v-if="jsonResi && jsonResi.length > 0" class="StepProgress" style="margin:10px;">
                <div class="StepProgress-item" v-for="(item,i) in jsonResi" :key="i-'-resi'">
                    <strong>
                        {{item.datetime}}
                    </strong> 
                    {{item.description}}
                    <div v-if="item.image_path" >
                        <img :src="item.image_path" width="40%">
                    </div>
                    
                </div>
            </div>
        </Popup>

        <Popup title="Alasan Pembatalan Pesanan" id="popup-cancel">
            <div style=" padding: 10px; " class="ol-dashboard__order-cancel">
                <LoadingImg v-if="loadingCancel" />
				<Alert v-if="alertMessage" type="danger" :message="alertMessage"/>

                <label class="ol-input-label">
                    <input class="ol-input--radio-check" v-model="cancelValue" value="Ingin mengubah alamat pengiriman pesanan" type="radio" checked>
                    <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
                    Ingin mengubah alamat pengiriman pesanan
                </label>

                <label class="ol-input-label">
                    <input class="ol-input--radio-check" v-model="cancelValue" value="Ingin mengubah detail pesanan (varian, warna, jumlah)" type="radio">
                    <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
                    Ingin mengubah detail pesanan (varian, warna, jumlah)
                </label>

                <label class="ol-input-label">
                    <input class="ol-input--radio-check" v-model="cancelValue" value="Ingin mengubah metode pembayaran" type="radio">
                    <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
                    Ingin mengubah metode pembayaran
                </label>

                <label class="ol-input-label">
                    <input class="ol-input--radio-check" v-model="cancelValue" value="Tidak ingin membeli" type="radio">
                    <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
                    Tidak ingin membeli
                </label>

                <label class="ol-input-label">
                    <input class="ol-input--radio-check" v-model="cancelValue" value="0" type="radio">
                    <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
                    Lainnya
                    <textarea v-model="cancelText" class="ol-input ol-input--textarea"></textarea>
                </label>

                <button style="margin-right:7px;" type="button" @click="onCancel()" class="ol-btn" value="Konfirmasi">Konfirmasi</button>
            </div>
        </Popup>
    </div>
</template>
<script>
import Popup from '@/components/popup/Popup.vue'
import ConfirmPopup from '@/components/popup/ConfirmPopup.vue'
import globalUrl from '@/util/globallUrl'
import helpme from '@/util/helpme'  
import LoadingImg from '@/components/loading'
import SkeletonLoader from '@/components/loading/skeletonLoader'
import {mapActions,mapGetters} from "vuex";

const ORDER = globalUrl.WS_PROFILE.ORDER;
export default {
    name:"OrderDetail",
    components:{
        Popup,
        ConfirmPopup,
        SkeletonLoader,
        LoadingImg,
    },
    data() {
        return {
            loadingDetailOrder:true,
            loadingCancel: false,
            loadingComplete: false,
            order:null,
            orderSn:'',
            data: null,
            infoResi:'',
            jsonResi:[],
            sub_total:'',
            fee:'',
            total_onngkir:'',
            voucher:'',
            cancelValue:null,
            cancelText:"",
            alertMessage:"",
            // wa:"https://api.whatsapp.com/send/?phone="+process.env.VUE_APP_WA_CS+"&app_absent=0&text=Hai+MORE%2C+saya+ingin+bertanya",
            wa: null,
        }
    },
    computed:{
        ...mapGetters('home',['allSetting']),
        tenor_txt: function() {
            if(this.data.tenor > 0) return this.data.tenor + " bulan"
			return "";
        }
    },
    methods:{
        ...mapActions('home',['getSetting']),

        async getData(){
            this.loadingDetailOrder = true;
            await ORDER.GET_DETAIL(this.$route.params.ordersn).then(response => {
                let _data = response.data.data.order;
                let lastResiDescription = response.data.data.jsonLastResi ? response.data.data.jsonLastResi.description : "";
                this.infoResi = response.data.data.infoResi;
                this.infoResi = this.infoResi ? this.infoResi +" "+lastResiDescription : "";
                this.jsonResi = response.data.data.jsonResi;
                this.data = _data;
                localStorage.setItem("detailOrder",JSON.stringify(response.data.data));
                this.summary();

                this.wa = this.wa+" (No.Pesanan: "+_data.ordersn+")";

                return true;
            }).catch(error => {
                // console.log("getdata",error)
                return false;
            });
            this.loadingDetailOrder=false;
        },
        async toCart(products){
            await this.$store.dispatch("transaction/cart/buyAgain",products);
            window.location.href = '/cart';
            // this.$router.push('/cart');
        },

        summary(){
            let sub_total = 0;
            let total_onngkir = 0;
            this.voucher = helpme.toRp(this.data.voucher_amount);
            this.data.r_more_transaction_order_details[0].r_more_transaction_order_products.map((item)=>{
                total_onngkir += _.toNumber(item.price_delivery);
                sub_total += _.toNumber(item.sub_total);
            });
            this.sub_total = helpme.toRp(sub_total);
            if(this.data.service_fee) this.fee = helpme.toRp(this.data.service_fee);
            this.total_onngkir= total_onngkir > 0 ? helpme.toRp(total_onngkir) : 'Gratis';
        },
        showPopup(id = null, show = true) {
            show ? Popup.methods.show(id) : Popup.methods.hide(id);
        },
        showConfirmPopup(id = null, show = true) {
            show ? ConfirmPopup.methods.show(id) : ConfirmPopup.methods.hide(id);
        },
        async onCancel(){

            this.alertMessage = null;
            if(this.cancelValue == 0 && !this.cancelText) {
                this.alertMessage = "Deskripsi lainnya wajib dilengkapi!";
            }else if(!this.cancelValue){
                this.alertMessage = "Pilih alasan pembatalan terlebih dahulu!";
            }else{
                this.loadingCancel = true;
                let note = (this.cancelValue == 0) ? this.cancelText : this.cancelValue;
                await ORDER.CANCEL(this.data.ordersn,note).then(response => {
                    this.getData();
                    this.showPopup("popup-cancel",false);
                }).catch(error => {
                    this.$toast.open({ message: "Gagal batalkan pesanan, coba lagi nanti!", type: "danger", duration: 5000, });
                });

                this.loadingCancel = false;
            }
        },
        async onComplete(){
            // this.showConfirmPopup('popup-complete',false);
            // this.$router.push("/profile/review");
            this.loadingComplete = true;
            await ORDER.COMPLETE(this.data.ordersn).then(response => {
                this.showConfirmPopup('popup-complete',false);
                this.$router.push("/profile/review");
                // this.getData();
            }).catch(error => {
                this.$toast.open({ message: "Gagal menerima pesanan, coba lagi nanti!", type: "danger", duration: 5000, });
            });
            this.loadingComplete = false;
            this.showConfirmPopup('popup-complete',false);
        },
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
    },
    beforeRouteLeave(to, from, next){
        this.showConfirmPopup('popup-complete',false);
        this.showPopup("popup-cancel",false);
        next();
    },
    // destroyed(){
    //     this.showConfirmPopup('popup-complete',false);
    //     this.showPopup("popup-cancel",false);
    // },
    async mounted(){
        this.jqScrolling2top();
		await this.getData();
        await this.getSetting();
        this.wa = this.allSetting.wa_link;
	},
    watch:{
        cancelValue:function(value){
            if(value != "0") this.cancelText = "";
        }
    }
}
</script>
<style scoped>
    .StepProgress {
        position: relative;
        padding-left: 45px;
        list-style: none;
        overflow-y: auto; height:300px;
    }
    .StepProgress::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        width: 10px;
        height: 100%;
        border-left: 2px solid #CCC;
    }
    .StepProgress-item {
        position: relative;
        counter-increment: list;
    }
    .StepProgress-item:not(:last-child) {
        padding-bottom: 20px;
    }
    .StepProgress-item::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -30px;
        height: 100%;
        width: 10px;
    }
    .StepProgress-item::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -37px;
        width: 12px;
        height: 12px;
        border: 2px solid #CCC;
        border-radius: 50%;
        background-color: #FFF;
    }
    .StepProgress-item.is-done::before {
        border-left: 2px solid green;
    }
    .StepProgress-item.is-done::after {
        content: "✔";
        font-size: 10px;
        color: #FFF;
        text-align: center;
        border: 2px solid green;
        background-color: green;
    }
    .StepProgress-item.current::before {
        border-left: 2px solid green;
    }
    .StepProgress-item.current::after {
        content: counter(list);
        padding-top: 1px;
        width: 19px;
        height: 18px;
        top: -4px;
        left: -40px;
        font-size: 14px;
        text-align: center;
        color: green;
        border: 2px solid green;
        background-color: white;
    }
    .StepProgress strong {
        display: block;
    }

</style>